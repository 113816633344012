import React from 'react';

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 to-gray-800 text-white flex flex-col">
      <header className="p-4">
        <a href="https://nexterras.com/contact-us" className="inline-block">
          {/* Replace the div below with your actual logo image */}
          <img src="/NexterraLogo.png" alt="Nexterras Logo" className="w-32 h-auto" />
        </a>
      </header>
      
      <main className="flex-grow flex items-center justify-center px-4">
        <div className="text-center max-w-2xl">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-blue-400">Need help with your claim?</span><br />
            Chat with us now!
          </h1>
          <p className="text-xl mb-12">
            Our experts are ready to assist you with your auto repair and insurance claim process. 
            Get immediate support through our chat service.
          </p>
          <p className="text-2xl mb-8 font-semibold">
            <span className="relative inline-block">
              <span className="relative z-10 text-white">Click the chat bubble</span>
              <span className="absolute bottom-1 left-0 w-full h-3 bg-blue-400 opacity-60"></span>
            </span>{" "}
            <span className="text-white">in the bottom right to get started</span>
          </p>
          <p className="mt-12 text-blue-300 text-lg">
            Available Monday - Friday 8 am - 6 pm CST for all your claim and repair needs
          </p>
        </div>
      </main>

      <footer className="p-4 text-center text-gray-400">
        © 2024 Nexterra. All rights reserved.
      </footer>
    </div>
  );
}

export default App;